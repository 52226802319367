.container iframe {
    width: 100% !important;
    background-color: red;
}

.googleContainer {
  position: relative;
  min-height: 40px;
}

.googleContainer .button {
  position: absolute;
  top: 0px;
  min-height: 40px;
}


.googleContainer .oneTap {
  z-index: 2001;
 background-color: white;
}

